import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { CaptureQueryParamInSessionStorage } from "../../atoms/CaptureQueryParamInSessionStorage/CaptureQueryParamInSessionStorage";
import { QueryParamCaptureKey } from "../../atoms/CaptureQueryParamInSessionStorage/QueryParamCaptureKey";
import { SampleHomePageGallery } from "../../molecules/SampleGallery/SampleHomePageGallery";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { Hero } from "./Hero";
import { StyleSection } from "./StyleSection";

export const HomePage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("homePage.title");
    const description = t("homePage.description");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.Home}>
            <CaptureQueryParamInSessionStorage queryParamKey={QueryParamCaptureKey.Coupon} />
            <Hero />
            <StyleSection />
            <SampleHomePageGallery />
        </ScrollablePageTemplate>
    );
};
