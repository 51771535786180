import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { SampleGalleryStyleAnnotated } from "./SampleGalleryStyleAnnotated";
import { transformQueryToGalleryProps } from "./transformQueryToGalleryProps";

export const SampleHomePageGallery: React.FC = () => {
    const staticData = useStaticQuery<Queries.SampleHomePageGalleryQuery>(graphql`
        query SampleHomePageGallery {
            allFile(filter: { relativeDirectory: { in: ["sample-buff", "sample-anime", "sample-couples"] } }) {
                edges {
                    node {
                        id
                        relativePath
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                width: 500
                                height: 500
                                transformOptions: { fit: COVER, cropFocus: NORTH }
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
    `);
    console.log(staticData);
    const photos = transformQueryToGalleryProps(staticData, 1);

    return <SampleGalleryStyleAnnotated photos={photos} />;
};
