import { Link } from "gatsby";
import * as React from "react";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import obamaAnime from "./obama-anime.jpg";
import obamaWatermark from "./obama-watermark.png";
import obamaSwift from "./obamaswift.jpeg";

export const StyleSection: React.FC = () => {
    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div className="sm:flex sm:items-baseline sm:justify-between">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900">Portrait Styles</h2>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                    <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
                        <img
                            alt="Taylor swift and Obama as a couple."
                            src={obamaSwift}
                            className="object-cover object-top group-hover:opacity-75"
                        />
                        <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
                        <div className="flex items-end p-6">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <Link to={HeadbotRoute.Couples}>
                                        <span className="absolute inset-0" />
                                        Couples
                                    </Link>
                                </h3>
                                <button
                                    aria-hidden="true"
                                    type="button"
                                    className="rounded bg-white/10 px-2 py-1 mt-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                >
                                    Create Now →
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
                        <img
                            alt="Swole Obama with a watermark."
                            src={obamaWatermark}
                            className="object-contain object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                        />
                        <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" />
                        <div className="flex items-end p-6 sm:absolute sm:inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <Link to={HeadbotRoute.Buff}>
                                        <span className="absolute inset-0" />
                                        Swole and Buff
                                    </Link>
                                </h3>
                                <button
                                    aria-hidden="true"
                                    type="button"
                                    className="rounded bg-white/10 px-2 py-1 mt-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                >
                                    Create Now →
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
                        <img
                            alt="Obama as an anime Naruto character."
                            src={obamaAnime}
                            className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                        />
                        <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" />
                        <div className="flex items-end p-6 sm:absolute sm:inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <Link to={HeadbotRoute.Naruto}>
                                        <span className="absolute inset-0" />
                                        Naruto
                                    </Link>
                                </h3>
                                <button
                                    aria-hidden="true"
                                    type="button"
                                    className="rounded bg-white/10 px-2 py-1 mt-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                >
                                    Create Now →
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
