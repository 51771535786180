import { JobStyle } from "@headbot/library";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";

interface ISampleStyleAnnotedGalleryImage {
    readonly id: string;
    readonly src: IGatsbyImageData;
    readonly alt: string;
    readonly caption: string;
    readonly style: JobStyle;
}
interface ISampleGalleryProps {
    readonly photos: ReadonlyArray<ISampleStyleAnnotedGalleryImage>;
}

const getHrefFromStyle = (style: JobStyle): string => {
    switch (style) {
        case JobStyle.Buff:
            return HeadbotRoute.Buff;
        case JobStyle.Anime:
        case JobStyle.Naruto:
            return HeadbotRoute.Naruto;
        case JobStyle.Couple:
            return HeadbotRoute.Couples;
        default:
            return HeadbotRoute.Couples;
    }
};

const getCaptionFromStyle = (style: JobStyle): string => {
    switch (style) {
        case JobStyle.Buff:
            return "Buff";
        case JobStyle.Anime:
        case JobStyle.Naruto:
            return "Naruto";
        case JobStyle.Couple:
            return "Couples";
        default:
            return "Couples";
    }
};

export const SampleGalleryStyleAnnotated: React.FC<ISampleGalleryProps> = ({ photos }) => {
    const images = photos.map((product) => {
        const { src, style, alt, caption } = product;
        const href = getHrefFromStyle(style);
        const styleCaption = getCaptionFromStyle(style);
        return (
            <div key={product.id} className="group relative">
                <div>
                    <GatsbyImage
                        alt={alt}
                        image={src}
                        className="w-full h-full object-cover object-center lg:w-full lg:h-full group-hover:opacity-75"
                    />
                </div>
                <div className="mt-4 flex justify-between">
                    <div>
                        <h3 className="text-sm text-gray-700">
                            <Link to={href}>
                                <span aria-hidden="true" className="absolute inset-0" />
                                {styleCaption}
                            </Link>
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">{caption}</p>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900">See it in action</h2>

                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">{images}</div>
                </div>
            </div>
        </>
    );
};
